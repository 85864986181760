import { envLocal, envProd } from "../util/environment";
import { APP_TYPE } from "../util/config.js";
import axios from "axios";

const USER_TOKEN = JSON.stringify(sessionStorage.getItem("USER_TOKEN"));

const AUTH_TOKEN = USER_TOKEN ? `Token ${USER_TOKEN}` : "";

axios.defaults.baseURL =
  APP_TYPE === "PROD" ? envProd.API_BASE_URL : envLocal.API_BASE_URL;
axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] = "application/json";

//GET
const getRequest = payload =>
  axios({
    method: "GET",
    url: payload.url
  })
    .then(response => {
      if (response.data) {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response.status >= 400 && error.response.status <= 500) {
        window.location.href = "/404";
      }
    });

//POST
const postRequest = payload =>
  axios({
    url: payload.url,
    data: payload.data
  })
    .then(response => {
      if (response.data) {
        return response.data;
      }
    })
    .catch(error => {
      console.log(error);
      if (error.response.status >= 400 && error.response.status <= 500) {
        window.location.href = "/404";
      }
    });

//apis here...

const createCustomer = payload => {
  const data = {
    url: "/api/createCustomerPhone",
    data: payload
  };

  return postRequest(data);
};

const updateCardDetails = payload => {
  const data = {
    url: "/api/createCustomerPhone",
    data: payload
  };
  return postRequest(data);
};

export { getRequest, postRequest, createCustomer, updateCardDetails };

import React, { Component } from "react";
import "./index.css";
import { Icon, Tooltip, Spin } from "antd";
import logo from "../assets/logo.svg";
import andrawsavings from "../assets/undrawsavings.png";
import rightarrow from "../assets/right-arrow.svg";
import InputMask from "react-input-mask";
import lock from "../assets/lock.svg";
import welcomebg from "../assets/welcomebg.jpg";
import { createCust } from "../store/actions";
import axios from "axios";
import { envProd } from "../util/environment";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneTemp: "",
      phone: "",
      error: "",
      loading: false
    };
  }

  handleSubimit = () => {
    const { phone } = this.state;
    const { dispatch } = this.props;
    if (!phone.trim()) {
      this.setState({ error: "Please fill in your phone number." });
      return;
    }
    if (phone.trim().length !== 15) {
      this.setState({ error: "Phone number must be a 10 digit." });
      return;
    }
    if (phone.trim()) {
      // var data = new FormData();
      // data.append("phone", this.state.phone);

      this.setState({ loading: true });

      const payload = {
        phone: this.state.phone
      };

      //console.log(createCust(payload)(dispatch));

      axios({
        method: "POST",
        url: envProd.API_BASE_URL + "/api/createCustomerPhone",
        data: payload
      }).then(res => {
        if (res.data.data.error) {
          this.setState({ error: res.data.data.error.message });
        }
        if (res.data.data.result) {
          this.setState({ loading: false });
          const { history } = this.props;
          sessionStorage.setItem("USER_TOKEN", res.data.data.result.token);
          sessionStorage.setItem("USER_ID", res.data.data.result.customer._id);
          history.push("/creditcard");
        }
      });
    } else {
      this.setState({ error: "Please fill your Phone Number" });
    }
  };

  // handleChange = e => {
  //   console.log(e.keyCode);
  //   let value = e.target.value;
  //   //this.setState({ [e.target.name]: e.target.value });

  //   if (value.length < 4 && value.length < 12) {
  //     this.setState({ phone: value }, () => {
  //       console.log(this.state);
  //     });
  //     return;
  //   }

  //   //when user selects
  //   if (value.length === 12) {
  //     let s = parseFloat(value.substring(2, value.length));
  //     if (isNaN(s)) {
  //       console.log("wn length equals to 12");
  //       console.log(typeof value.substring(2, value.length));
  //       console.log(parseInt(value.substring(2, value.length)));
  //       let val =
  //         "+1" +
  //         value.substring(0, 1) +
  //         "-" +
  //         value.substring(2, 4) +
  //         "-" +
  //         value.substring(5, 7) +
  //         "-" +
  //         value.substring(8, 11);
  //       this.setState({ phone: val }, () => {
  //         console.log(this.state);
  //       });
  //       return;
  //     }
  //     this.setState({ phone: value.substring(0, 11) });
  //     return;
  //   }
  //   if (value.length === 4) {
  //     //when user types
  //     if (!isNaN(parseInt(value.substr(2, 4)))) {
  //       let val = "+1-" + value.substr(0, 3) + "-" + value.substr(3, 4);
  //       this.setState({ phone: val }, () => {
  //         console.log(this.state);
  //       });
  //       return;
  //     }
  //     this.setState({ phone: value.substring(0, 4) });
  //     return;
  //   }
  //   if (value.length > 4 && value.length < 12) {
  //     this.setState({ phone: value }, () => {
  //       console.log(this.state);
  //     });
  //   }

  //   if (value.length === 11) {
  //     let val = value.substr(0, 10) + "-" + value.substring(10, 12);
  //     this.setState({ phone: val }, () => {
  //       console.log(this.state);
  //     });
  //     return;
  //   }
  //   this.setState({ phone: value }, () => {
  //     console.log(this.state);
  //   });
  // };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // console.log(oldState);
    // console.log(selection);
    // console.log(value);
    // console.log(cursorPosition);
    // console.log(userInput);

    // keep minus if entered by user
    if (
      value.endsWith("-") &&
      userInput !== "-" &&
      !this.state.phone.endsWith("-")
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    if (userInput && userInput.startsWith("+1")) {
      value = userInput.substr(1, userInput.length);
    }

    return {
      value,
      selection
    };
  };

  handleChange = e => {
    if (e.target.value.length > 3) {
      this.setState({
        mask: "(999) 999-9999",
        phone: e.target.value,
        error: null
      });
    }
    if (e.target.value.length <= 3) {
      this.setState({ mask: "999 999-9999", phone: e.target.value });
    }
  };

  render() {
    const { phone, error, loading } = this.state;
    const antIcon = (
      <Icon
        type="loading"
        style={{ fontSize: 20, color: "#fff", paddingLeft: "15px" }}
        spin
      />
    );

    return (
      <div className="fullwidth">
        <div className="container">
          <div className="header">
            <img src={logo} alt="bill" className="logobilltrim" />
          </div>
          <div className="welcomebody">
            <div className="bannertexttop">
              <div>
                <p className="textleft">
                  Reduce your <br />
                  AT&T bill.
                </p>
                <span className="textleft">Instantly.</span>
              </div>
              <div className="averagecard">
                <div className="average">
                  Average <b>BillTrim</b> user saves <b>$425</b>
                </div>
                <div className="enterphonebox">
                  <Tooltip
                    title={
                      <span className="errtooltip">
                        <Icon type="exclamation-circle" /> {error}
                      </span>
                    }
                    style={{ border: "1px solid red" }}
                    overlayClassName="tooltip"
                    visible={error ? true : false}
                    placement="bottomLeft"
                  >
                    <InputMask
                      //autoFocus
                      type="tel"
                      autoComplete="off"
                      mask="+1 999-999-9999"
                      maskChar={null}
                      className="phoneInput"
                      name="phone"
                      disabled={loading ? true : false}
                      placeholder="Your AT&T phone number"
                      onChange={this.handleChange}
                      beforeMaskedValueChange={this.beforeMaskedValueChange}
                      onFocus={() => this.setState({ error: "" })}
                    />
                    {/* <input
                      className="phoneInput"
                      type="text"
                      name="phone"
                      maxLength={15}
                      value={phone}
                      // onKeyDown={e => console.log(e)}
                      placeholder="Your AT&T phone number"
                      onChange={e => console.log(e)}
                      onFocus={() => this.setState({ error: "" })}
                    /> */}
                  </Tooltip>
                  <button className="btnStyle" onClick={this.handleSubimit}>
                    Check my number for savings
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <img
                        style={{ padding: "0px 0px 0px 15px" }}
                        src={rightarrow}
                        alt="rightarrow"
                      />
                    )}
                  </button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    height="12"
                    alt="lock"
                    style={{ marginBottom: "3px" }}
                    src={lock}
                  />
                  <span className="desc">
                    Your info is secured. We don’t spam
                  </span>
                </div>
              </div>
              <img
                alt="andrawsavings"
                className="bannerrightimg"
                src={andrawsavings}
              />
            </div>
          </div>
        </div>
        <div className="bottomimg">
          <img alt="welcomebg" src={welcomebg} />
        </div>
      </div>
    );
  }
}

export default Welcome;

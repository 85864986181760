import { SAVE_CUSTOMER } from "../types";

const initialState = {
  customer: {}
};

function saveCustomer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CUSTOMER:
      const data = {
        ...state,
        customer: action.customer
      };
      return data;
    default:
      return state;
  }
}

export { saveCustomer };

import React from "react";
import { isMobile } from "react-device-detect";
import "./index.css";
import logo from "../assets/logo.svg";
import billtrim from "../assets/billtrim.svg";
import thankyou from "../assets/thankyou.png";
import { Redirect } from "react-router-dom";

export default () => {
  const token = JSON.stringify(sessionStorage.getItem("USER_TOKEN"));
  return (
    <div className="container">
      <div className="header">
        <img src={logo} alt="bill" className="logobilltrim" />
      </div>
      <br />
      <div className="thankyou">
        <p>Thank You!</p>
        <span>
          We’re working on your AT&T bill,
          <br />
          We’ll call you once we find savings!
        </span>
        <img alt="thankyou" src={thankyou} />
      </div>
      {token === "null" || token === "" ? <Redirect to="/" /> : ""}
    </div>
  );
};

import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import axios from "axios";
import done from "../../assets/done.svg";
import { Tooltip, Icon, Spin } from "antd";
import { envProd } from "../../util/environment";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: "16px",

        color: "#808080",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#707070",
          fontWeight: 300,
          fontSize: "13px"
        },
        padding
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

const handleBlur = () => {
  //console.log("[blur]");
};
const handleChange = change => {
  //console.log("[change]", change);
};
const handleClick = () => {
  //console.log("[click]");
};
const handleFocus = () => {
  //console.log("[focus]");
  this.setState({ error: { creditCard: "" } });
};
const handleReady = () => {
  // console.log("[ready]");
};

class _CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      error: {
        firstName: "",
        lastName: "",
        creditCard: ""
      },
      loading: false
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    let APIURL = envProd.API_BASE_URL;
    const { firstname, lastname } = this.state;
    if (!firstname.trim()) {
      this.setState({ error: { firstName: "First name is required" } });
      return;
    }
    if (!lastname.trim()) {
      this.setState({ error: { lastName: "Last name is required" } });
      return;
    }

    if (this.props.stripe) {
      console.log(this.props.stripe);

      this.props.stripe.createToken().then(payload => {
        if (payload.error) {
          this.setState({ error: { creditCard: "Invalid card details" } });
          return;
        }
        if (payload.token) {
          const { token } = payload;

          this.setState({ loading: true });

          const carddata = {
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            _id: sessionStorage.getItem("USER_ID"),
            stripe_token: token.id,
            card_last_four: token.card.last4,
            card_brand: token.card.brand
          };

          axios({
            method: "POST",
            url: APIURL + "/api/saveCardAndName",
            headers: {
              Authorization: `Token ${sessionStorage.getItem("USER_TOKEN")}`
            },
            data: carddata
          })
            .then(res => {
              this.setState({ loading: false });
              const { history } = this.props;
              history.push("/thankyou");
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  render() {
    const { firstname, lastname, error, loading } = this.state;
    const antIcon = (
      <Icon
        type="loading"
        style={{ fontSize: 20, color: "#fff", paddingLeft: "15px" }}
        spin
      />
    );
    return (
      <div>
        <form className="creditcardform">
          <div>
            <h2 className="payment">
              Enter the payment method below and we will get to work. You will
              hear from us in 24 hours with awesome savings!
            </h2>
            <div className="creditcardforminner">
              <div className="firsttwoinput">
                <div>
                  <Tooltip
                    title={
                      <span className="errtooltip">
                        <Icon type="exclamation-circle" /> {error.firstName}
                      </span>
                    }
                    overlayClassName="tooltip"
                    visible={error.firstName ? true : false}
                    placement="bottomLeft"
                  >
                    <input
                      autoComplete="off"
                      className="halfspace"
                      type="text"
                      disabled={loading ? true : false}
                      style={{ fontWeight: "normal", fontSize: "16px" }}
                      placeholder="First Name"
                      onChange={this.handleChange}
                      onFocus={() =>
                        this.setState({ error: { firstName: "" } })
                      }
                      name="firstname"
                      value={firstname}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    title={
                      <span className="errtooltip">
                        <Icon type="exclamation-circle" /> {error.lastName}
                      </span>
                    }
                    overlayClassName="tooltip"
                    visible={error.lastName ? true : false}
                    placement="bottomLeft"
                  >
                    <input
                      autoComplete="off"
                      className="halfspace"
                      type="text"
                      disabled={loading ? true : false}
                      style={{ fontWeight: "normal", fontSize: "16px" }}
                      onChange={this.handleChange}
                      onFocus={() => this.setState({ error: { lastName: "" } })}
                      name="lastname"
                      placeholder="Last Name"
                      value={lastname}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="cardinput">
                <Tooltip
                  title={
                    <span className="errtooltip">
                      <Icon type="exclamation-circle" /> {error.creditCard}
                    </span>
                  }
                  overlayClassName="tooltip"
                  visible={error.creditCard ? true : false}
                  placement="bottomLeft"
                >
                  <CardElement
                    onBlur={handleBlur}
                    disabled={loading ? true : false}
                    onChange={handleChange}
                    onFocus={() => this.setState({ error: { creditCard: "" } })}
                    onReady={handleReady}
                    {...createOptions(this.props.fontSize)}
                  />
                </Tooltip>
              </div>
              <button className="creditcardbtn" onClick={this.handleSubmit}>
                Join 100,000+ happy customers{" "}
                {loading && <Spin indicator={antIcon} />}
              </button>

              <div style={{ textAlign: "center" }}>
                <img
                  height="12"
                  src={done}
                  alt="done"
                  style={{ marginBottom: "3px" }}
                />
                <span className="desc">
                  Average BillTrim user saves $425 on AT&T
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const CardForm = injectStripe(_CardForm);
export default CardForm;

import React, { Component } from "react";
import "antd/dist/antd.css";
import Welcome from "./pages/Welcome";
import ThankYou from "./pages/ThankYou";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import CreditCrad from "./pages/creditcard/CreditCard";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ""
    };
  }

  componentDidUpdate = () => {
    const token = JSON.stringify(sessionStorage.getItem("USER_TOKEN"));
    this.setState({ token });
  };

  render() {
    const { token } = this.state;
    //const token = JSON.stringify(sessionStorage.getItem("USER_TOKEN"));

    return (
      <Router>
        <div>
          <Route path="/" exact component={Welcome} />
          <Route path="/creditcard" component={CreditCrad} />
          <Route path="/thankyou" component={ThankYou} />
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
import { StripeProvider } from "react-stripe-elements";
import Checkout from "./Checkout";
import logo from "../../assets/logo.svg";
import "../index.css";
import rightleaf from "../../assets/assettwo.png";
import { Redirect } from "react-router-dom";

class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const token = JSON.stringify(sessionStorage.getItem("USER_TOKEN"));

    return (
      <React.Fragment>
        <div className="container">
          <div className="header">
            <img src={logo} alt="bill" className="logobilltrim" />
          </div>
          <div className="creditcardToptext">
            We negotiate your bills on your behalf and keep a 25% cut of your
            annual savings--we need to eat too 😜 You save nothing, you pay
            nothing. We do the work so you get free money. Sound good?
          </div>
          <div className="creditcardbox">
            <StripeProvider apiKey="pk_test_7tDBLYrGn6BqpgNV7hbOXqeC">
              <Checkout history={this.props.history} />
            </StripeProvider>
          </div>
          {/* <img className="rightleaf" src={rightleaf} alt="rightleaf" /> */}
        </div>
        {token === "null" || token === "" ? <Redirect to="/" /> : ""}
      </React.Fragment>
    );
  }
}

export default CreditCard;
